<template>
  <b-modal
    id="modal-set-service-fee"
    :title="$t('flight.setServiceFee')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    hide-footer
    @show="openModalHandle"
  >
    <b-tabs pills>
      <!-- custom sFee -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="EditIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('flight.setServiceFee') }}</span>
        </template>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="amount-adult"
            >
              <template #label>
                <h5>{{ $t('fee.adult') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-adult"
                :value-money.sync="customFeeServiceDataToModify.adultAmount"
                class="flex-grow-1"
                @input="val => handleInputAdultAmount(val)"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="amount-child"
            >
              <template #label>
                <h5>{{ $t('fee.children') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-child"
                :value-money.sync="customFeeServiceDataToModify.childAmount"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="amount-infant"
            >
              <template #label>
                <h5>{{ $t('fee.infant') }}</h5>
              </template>
              <IAmInputMoney
                id="amount-infant"
                :value-money.sync="customFeeServiceDataToModify.infantAmount"
                class="flex-grow-1"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <div class="float-right my-50">
          <b-button
            variant="info"
            @click="modifySetServiceFee"
          >
            {{ $t('edit') }}
          </b-button>
        </div>
      </b-tab>

      <!-- use profile -->
      <b-tab :active="isActiveProfile">
        <template #title>
          <feather-icon
            icon="ToolIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('flight.useProfileServiceFee') }}</span>
        </template>
        <div class="pb-1">
          <IAmOverlay
            :loading="!employeeProfileList"
          >
            <app-collapse
              accordion
              type="margin"
            >
              <app-collapse-item
                v-for="(employeeProfileItem, employeeProfileIndex) in employeeProfileList"
                :key="employeeProfileIndex"
                title
              >
                <template #header>
                  <div class="d-flex-between w-100 mr-2">
                    <span class="text-heading-4">{{ employeeProfileItem.name }}
                      <BBadge
                        v-if="employeeProfileItem.isDefault"
                        variant="success"
                        class="badge-glow round pills ml-50"
                      >
                        {{ $t('fee.default') }}
                      </BBadge>
                    </span>
                    <em
                      v-if="selectedProfileBefore ? selectedProfileBefore.id === employeeProfileItem.id : (feeServiceBefore.useCustom === true ? false : employeeProfileItem.isDefault)"
                      class="text-heading-5"
                    >
                      {{ $t('using') }}
                    </em>
                    <b-button
                      v-else
                      variant="flat-info"
                      class="text-heading-5 py-25"
                      pill
                      @click.prevent.stop="handleApplyProfile(employeeProfileItem)"
                    >
                      {{ $t('apply') }}
                    </b-button>
                  </div>
                </template>

                <div>
                  <BTable
                    :fields="tableColumns"
                    striped
                    bordered
                    hover
                    responsive
                    :empty-text="$t('noMatchingResult')"
                    :items="employeeProfileItem.serviceFeeConfigs"
                  >
                    <template
                      v-for="column in tableColumns"
                      v-slot:[`head(${column.key})`]="data"
                    >
                      <div
                        :key="column.label"
                        class="text-dark text-nowrap"
                        :class="{
                          'text-right': ['adultAmount', 'childAmount', 'infantAmount'].includes(data.label),
                          'text-center': ['action'].includes(data.label)
                        }"
                      >
                        {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
                      </div>
                    </template>
                    <template #cell(feeType)="data">
                      {{ $t(`fee.${data.item.feeType}`) }}
                    </template>
                    <template #cell(ticketType)="data">
                      {{ $t(`fee.${data.item.ticketType}`) }}
                    </template>
                    <template #cell(adultAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.adultAmount) }}
                      </div>
                    </template>
                    <template #cell(childAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.childAmount) }}
                      </div>
                    </template>
                    <template #cell(infantAmount)="data">
                      <div class="text-right">
                        {{ formatCurrency(data.item.infantAmount) }}
                      </div>
                    </template>
                  </BTable>
                </div>
              </app-collapse-item>
            </app-collapse>
          </IAmOverlay>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import {
  BFormGroup, BRow, BCol, BTab, BTabs, BButton, BTable, BBadge,
} from 'bootstrap-vue'
import { ref, toRefs, computed } from '@vue/composition-api'

import { apiEmployee } from '@/api'
import store from '@/store'
import { formatCurrency } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BTab,
    BTabs,
    BButton,
    BTable,
    BBadge,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    AppCollapse: () => import('@/@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@/@core/components/app-collapse/AppCollapseItem.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { customServiceFee } = useReservationHandle()
    const reservationsData = toRefs(props).reservationsData
    const employeeId = computed(() => store.getters['userStore/getMeDataId'])

    const feeServiceBefore = computed(() => {
      const result = {
        adult: null,
        child: null,
        infant: null,
      }
      reservationsData.value.paxLists.forEach(pax => {
        const type = pax.paxType.toLowerCase()
        if (result[type] === null) {
          result[type] = pax.customFeeService
        }
      })
      return {
        adultAmount: result.adult || null,
        childAmount: result.child || null,
        infantAmount: result.infant || null,
        useCustom: !!((result.adult || result.child || result.infant)),
      }
    })

    const selectedProfileBefore = computed(() => reservationsData.value.employeeProfile)

    const customFeeServiceDataToModify = ref({ ...feeServiceBefore.value })

    function modifySetServiceFee() {
      const payload = {
        paxLists: reservationsData.value.paxLists.map(item => ({
          ...item,
          customFeeService: customFeeServiceDataToModify.value[`${item.paxType.toLowerCase()}Amount`],
        })),
      }
      const id = reservationsData.value.id
      customServiceFee(id, payload)
        .then(() => { this.$bvModal.hide('modal-set-service-fee') })
    }

    // flagInit để tránh init `customFeeServiceDataToModify` làm thay đổi luôn `childAmount`
    let flagInit = false
    function handleInputAdultAmount(val) {
      if (flagInit) {
        customFeeServiceDataToModify.value.childAmount = val
      } else {
        flagInit = true
      }
    }

    const employeeProfileList = ref()
    async function openModalHandle() {
      if (!employeeProfileList.value) {
        const { data: res } = await apiEmployee.getEmployeeProfile(employeeId.value)
        employeeProfileList.value = res
      }
    }

    const tableColumns = ref([
      { label: 'airline', key: 'airline' },
      { label: 'feeType', key: 'feeType' },
      { label: 'ticketType', key: 'ticketType' },
      { label: 'adultAmount', key: 'adultAmount' },
      { label: 'childAmount', key: 'childAmount' },
      { label: 'infantAmount', key: 'infantAmount' },
    ])

    const isActiveProfile = computed(() => !!selectedProfileBefore.value || !feeServiceBefore.value.useCustom)

    function handleApplyProfile(employeeProfile) {
      const payload = {
        profileFeeId: employeeProfile.id,
        paxLists: reservationsData.value.paxLists.map(item => ({
          ...item,
          customFeeService: undefined,
        })),
      }
      const id = reservationsData.value.id
      customServiceFee(id, payload).then(() => {
        this.$bvModal.hide('modal-set-service-fee')
      })
      customFeeServiceDataToModify.value = {
        adult: null,
        child: null,
        infant: null,
        useCustom: false,
      }
    }
    return {
      customFeeServiceDataToModify,
      modifySetServiceFee,
      handleInputAdultAmount,
      feeServiceBefore,

      openModalHandle,
      employeeProfileList,
      tableColumns,
      formatCurrency,
      handleApplyProfile,
      selectedProfileBefore,
      isActiveProfile,
    }
  },
}
</script>
